<template>
  <page class="main-container base-scrollbar">
    <page-section
      full-width
      bottom-border
    >
      <div class="flex flex-row justify-between">
        <page-title>
          <div class="h3 flex items-center gap-4 mx-5">
            <ic-arrow
              class="cursor-pointer"
              @click="
                $router.push(
                  customerId
                    ? { name: 'audit:external:details', params: { id: customerId } }
                    : isExternal
                      ? { name: 'audit-report' }
                      : { name: 'site:reports:audit' },
                )
              "
            />
            <p>Audit History</p>
          </div>
        </page-title>
      </div>
    </page-section>
    <page-section full-width>
      <p class="text-bb-text-secondary pb-4 p3 mx-5">
        Here you can find the total number of audits conducted since {{ earliestAuditDate }}
      </p>
      <!-- search -->
      <text-input
        v-if="isExternal"
        v-model="searchQuery"
        class="w-64 ml-5"
        input-name="search-audits"
        input-id="search-audits"
        input-type="text"
        placeholder="Search Audits..."
      >
      </text-input>
      <bb-table
        class="mx-5 my-6 shadow-bb-shadow rounded-md"
        :headers="headers"
        :data="filteredAudits"
        :is-loading="isLoading"
        :scroll-y="true"
        :sticky-header="true"
        :default-sorting-order="defaultSortingOrder"
        max-table-height="calc(100vh - 400px)"
      >
        <template #rows="{ tableData }">
          <tbody>
            <tr
              v-for="audit in tableData"
              :key="audit.id"
              class="h-18 text-sm odd:bg-white even:bg-neutral-0"
            >
              <td
                v-if="customerId"
                class="px-4"
              >
                {{ audit.google_customer_name }}
                #{{ audit.auditNumber }}
              </td>
              <td
                v-else
                class="px-4"
              >
                {{ isExternal ? userAuditGoogleCredentials.googleCustomerName : selectedSite.label }}
                #{{ audit.auditNumber }}
              </td>
              <td class="px-4 text-left">{{ audit.score ? audit.score + '%' : '' }}</td>
              <td class="pl-4 pr-6 text-right pNum">{{ formatCreatedDate(audit.created_at) }}</td>
              <td class="px-4 text-center">
                <div
                  class="text-bb-brand-purple font-medium disabled cursor-pointer hover:opacity-75"
                  @click.stop="openAuditModal(audit)"
                >
                  View
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </bb-table>
    </page-section>
    <bb-base-modal
      v-if="showModal === true"
      :width="modalConfig.width"
      @close="closeModal"
    >
      <template #container>
        <HistoricalAuditModal
          :audit-id="selectedAudit.id"
          :audit-number="selectedAudit.auditNumber"
          :is-external="isExternal"
          @close="closeModal"
        ></HistoricalAuditModal>
      </template>
    </bb-base-modal>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection'
import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'
import BbTable from '@/components/table/BbTable'
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import HistoricalAuditModal from '@/components/modals/HistoricalAuditModal.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'

export default {
  name: 'AuditHistory',
  components: {
    Page,
    PageSection,
    IcArrow,
    BbTable,
    HistoricalAuditModal,
    BbBaseModal,
    TextInput,
  },
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedAudit: null,
      showModal: false,
      modalConfig: {
        width: '1296px',
      },
      headers: [
        {
          value: 'auditNumber',
          label: 'Audit',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'left',
        },
        {
          value: 'score',
          label: 'Score',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'created_at',
          label: 'Date',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'action',
          label: 'Action',
          sortable: false,
          sorting: null,
          position: 'center',
        },
      ],
      defaultSortingOrder: { order: 'ASCENDING', column: 'auditNumber' },
      searchQuery: '',
    }
  },

  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('audit', ['audits', 'auditsError']),
    ...mapGetters('auth', ['userAuditGoogleCredentials']),
    customerId() {
      // Use the route parameter 'id' as the customerId
      return this.$route.params.id
    },
    identification() {
      if (this.customerId) {
        return this.customerId // Use customerId if provided
      }
      return this.isExternal ? this.userAuditGoogleCredentials.googleCustomerId : this.selectedSite?.value
    },
    auditsClone() {
      return structuredClone(this.audits)
    },
    earliestAuditDate() {
      if (this.audits.length) {
        return dayjs(this.audits[this.audits.length - 1].created_at).format('MM/DD/YYYY')
      }
      return ''
    },
    filteredAudits() {
      if (!this.searchQuery) {
        return this.auditsClone.filter(audit => audit.status !== 'running' && audit.status !== 'pending')
      }
      const normalizedQuery = this.normalizeString(this.searchQuery)
      return this.auditsClone.filter(audit => {
        const normalizedCustomerId = this.normalizeString(
          this.isExternal ? this.userAuditGoogleCredentials.googleCustomerId : this.selectedSite.value,
        )
        return (
          normalizedCustomerId.includes(normalizedQuery) && audit.status !== 'running' && audit.status !== 'pending'
        )
      })
    },
  },

  async mounted() {
    this.isLoading = true
    await this.fetchAuditData()
    this.isLoading = false
  },

  methods: {
    ...mapActions('audit', ['fetchAudits', 'fetchExternalAudits']),
    async openAuditModal(audit) {
      this.selectedAudit = audit
      this.showModal = true
    },
    closeModal() {
      this.selectedAudit = null
      this.showModal = false
    },
    formatCreatedDate(date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    async fetchAuditData() {
      // Choose the appropriate action based on `isExternal`
      if (this.isExternal || this.customerId) {
        await this.fetchExternalAudits({ customerId: this.identification })
      } else {
        await this.fetchAudits({ siteId: this.identification })
      }
    },
    normalizeString(value) {
      return value.toString().replace(/[^0-9]/g, '')
    },
  },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 103px);
}
</style>
